import slideTo from '{dede:global.cfg_basehost/}/slideTo.js';
import slideToLoop from '{dede:global.cfg_basehost/}/slideToLoop.js';
import slideNext from '{dede:global.cfg_basehost/}/slideNext.js';
import slidePrev from '{dede:global.cfg_basehost/}/slidePrev.js';
import slideReset from '{dede:global.cfg_basehost/}/slideReset.js';
import slideToClosest from '{dede:global.cfg_basehost/}/slideToClosest.js';
import slideToClickedSlide from '{dede:global.cfg_basehost/}/slideToClickedSlide.js';

export default {
  slideTo,
  slideToLoop,
  slideNext,
  slidePrev,
  slideReset,
  slideToClosest,
  slideToClickedSlide,
};
