// Swiper Class
import Swiper from '{dede:global.cfg_basehost/}/core/core.js';

//IMPORT_MODULES

const modules = [
  //INSTALL_MODULES
];

Swiper.use(modules);

//EXPORT
