import appendSlide from '{dede:global.cfg_basehost/}/methods/appendSlide.js';
import prependSlide from '{dede:global.cfg_basehost/}/methods/prependSlide.js';
import addSlide from '{dede:global.cfg_basehost/}/methods/addSlide.js';
import removeSlide from '{dede:global.cfg_basehost/}/methods/removeSlide.js';
import removeAllSlides from '{dede:global.cfg_basehost/}/methods/removeAllSlides.js';

export default function Manipulation({ swiper }) {
  Object.assign(swiper, {
    appendSlide: appendSlide.bind(swiper),
    prependSlide: prependSlide.bind(swiper),
    addSlide: addSlide.bind(swiper),
    removeSlide: removeSlide.bind(swiper),
    removeAllSlides: removeAllSlides.bind(swiper),
  });
}
