import updateSize from '{dede:global.cfg_basehost/}/updateSize.js';
import updateSlides from '{dede:global.cfg_basehost/}/updateSlides.js';
import updateAutoHeight from '{dede:global.cfg_basehost/}/updateAutoHeight.js';
import updateSlidesOffset from '{dede:global.cfg_basehost/}/updateSlidesOffset.js';
import updateSlidesProgress from '{dede:global.cfg_basehost/}/updateSlidesProgress.js';
import updateProgress from '{dede:global.cfg_basehost/}/updateProgress.js';
import updateSlidesClasses from '{dede:global.cfg_basehost/}/updateSlidesClasses.js';
import updateActiveIndex from '{dede:global.cfg_basehost/}/updateActiveIndex.js';
import updateClickedSlide from '{dede:global.cfg_basehost/}/updateClickedSlide.js';

export default {
  updateSize,
  updateSlides,
  updateAutoHeight,
  updateSlidesOffset,
  updateSlidesProgress,
  updateProgress,
  updateSlidesClasses,
  updateActiveIndex,
  updateClickedSlide,
};
