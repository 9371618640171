import getTranslate from '{dede:global.cfg_basehost/}/getTranslate.js';
import setTranslate from '{dede:global.cfg_basehost/}/setTranslate.js';
import minTranslate from '{dede:global.cfg_basehost/}/minTranslate.js';
import maxTranslate from '{dede:global.cfg_basehost/}/maxTranslate.js';
import translateTo from '{dede:global.cfg_basehost/}/translateTo.js';

export default {
  getTranslate,
  setTranslate,
  minTranslate,
  maxTranslate,
  translateTo,
};
